import React, { Component } from "react"
import { gsap } from "gsap"
import PropTypes from "prop-types"

class ImageChanger extends Component {
  constructor() {
    super()
    this.tl = gsap.timeline({ paused: true, repeat: -1 })
    this.imageElements = []
  }
  componentDidMount() {
    if (this.imageElements.length < 2) return
    if (this.imageElements[0]) {
      gsap.set(this.imageElements[0], { autoAlpha: 1 })
    }
    this.imageElements.forEach((img, index) => {
      const nextTarget = this.imageElements[index + 1] || this.imageElements[0]
      this.tl.to(img, {
        duration: 1,
        autoAlpha: 0,
        delay: 3,
      })

      this.tl.to(
        nextTarget,
        {
          duration: 1,
          autoAlpha: 1,
        },
        "-=1"
      )
    })
  }

  componentDidUpdate(preProps) {
    if (preProps.active !== this.props.active) {
      if (this.props.active) {
        this.tl.restart(true)
      } else {
        this.tl.pause()
        gsap.delayedCall(0.3, () => {
          this.tl.pause(0)
        })
      }
    }
  }

  render() {
    return (
      <div className="image-changer-wrap">
        {this.props.images.map((image, index) => (
          <img
            src={image.publicURL}
            className="img-fluid"
            key={image.id}
            ref={e => (this.imageElements[index] = e)}
          />
        ))}
      </div>
    )
  }
}

ImageChanger.propTypes = {
  parent: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  active: PropTypes.bool,
}

ImageChanger.defaultProps = {
  parent: "",
  images: [{}],
  active: false,
}

export default ImageChanger
