import React, { Component } from "react"
import { graphql } from "gatsby"
import { gsap } from "gsap"

import ImageChanger from "../components/ImageChanger"
import Layout from "../components/layout"
import SEO from "../components/seo"

class EnsayosLaboratorio extends Component {
  constructor() {
    super()

    this.state = {
      selectedId: null,
      contentHeight: "100%",
    }

    this.windowWidth = 0

    this.selectEnsayo = this.selectEnsayo.bind(this)
    this.windowResizeHandler = this.windowResizeHandler.bind(this)

    // Object with all the DOM elements for the ensayos descriptions
    // Each element has a key equal to the ID of the ensayo
    this.ensayosContentElements = {}
    this.ensayosContentElementsTweens = {}
    this.selectorColumn = null
    this.selectorColumnTween = null
  }

  selectEnsayo(e) {
    this.setState({
      selectedId: e.target.getAttribute("data-target"),
    })
  }

  windowResizeHandler() {
    this.windowWidth = window.innerWidth
    if (this.windowWidth > 767) {
      this.selectorColumnTween.progress(0).reverse()
    } else if (this.windowWidth < 768 && this.state.selectedId !== null) {
      this.selectorColumnTween.play()
    }
  }
  // eslint-disable-next-line
  componentDidUpdate(preProps, preState) {
    const { selectedId } = this.state
    const preSelectedId = preState.selectedId
    const targetContentElement = selectedId
      ? this.ensayosContentElements[selectedId]
      : null

    if (preSelectedId !== selectedId) {
      this.setState({
        contentHeight: selectedId
          ? targetContentElement.clientHeight + 15
          : "100%",
      })
      // Animate the content elements
      if (preSelectedId !== null) {
        const preTarget = this.ensayosContentElements[preSelectedId]
        // There is a content element visible, aminate it out
        gsap.to(preTarget, {
          duration: 0.3,
          xPercent: -101,
          onComplete: () =>
            gsap.set(preTarget, {
              xPercent: 101,
            }),
        })
      }
      gsap.to(targetContentElement, {
        duration: 0.3,
        xPercent: 0,
      })
      // Toggle the left column animation if the width is less than 768
      if (this.windowWidth < 768) {
        this.selectorColumnTween.reversed(!this.selectorColumnTween.reversed())
      }
    }
  }

  componentDidMount() {
    this.windowWidth = window.innerWidth
    window.addEventListener("resize", this.windowResizeHandler)

    this.selectorColumnTween = gsap
      .to(this.selectorColumn, {
        xPercent: -101,
        duration: 0.3,
      })
      .reverse()
    // Set content elements tweens
    for (let element in this.ensayosContentElements) {
      gsap.set(this.ensayosContentElements[element], {
        xPercent: 101,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResizeHandler)
  }

  render() {
    const { header, main } = this.props.data.ensayosDataJson
    const { selectedId, contentHeight } = this.state
    return (
      <Layout location="/servicios">
        <SEO title="Ensayos de Laboratorio" />
        <div className="servicios-main-wrap">
          <div
            className="servicios-header py-3 ensayos"
            /* style={{
            backgroundImage: `url(${header.bg.childImageSharp.fluid.src})`,
          }} */
          >
            <div className="container">
              <div className="row align-items-center h-100">
                <div className="col-12">
                  <h2 className="text-center title">{header.title}</h2>
                  <p>{header.text}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="servicios-content ensayos">
            <div className="row no-gutters">
              <div
                className="col-12 col-md-4 col-lg-3 ensayos-left-col"
                ref={e => (this.selectorColumn = e)}
              >
                <ul className="ensayos-selector-wrap">
                  {main.map((e, i) => {
                    return (
                      <li className="ensayo-element-selector" key={e.ensayoId}>
                        <button
                          className={`ensayo-title${
                            selectedId === e.ensayoId ? " active" : ""
                          }`}
                          data-target={e.ensayoId}
                          onClick={this.selectEnsayo}
                        >
                          {e.ensayoTitle}
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="col-12 col-md-8 col-lg-9 ensayos-right-col">
                <button
                  className="selector-header"
                  data-target={null}
                  onClick={this.selectEnsayo}
                >
                  Seleccione un Ensayo
                </button>
                <div
                  className="ensayos-content"
                  style={{ height: contentHeight }}
                >
                  {main.map(e => (
                    <div
                      className="single-ensayo"
                      key={`content-${e.ensayoId}`}
                      ref={element =>
                        (this.ensayosContentElements[e.ensayoId] = element)
                      }
                    >
                      <h3 className="title">{e.ensayoTitle}</h3>
                      {e.content.map((content, i) => {
                        if (content.title !== null) {
                          return (
                            <div key={`content-${e.id}-${i}`}>
                              <h5>{content.contentTextTitle}</h5>
                              <p>{content.contentTextString}</p>
                            </div>
                          )
                        } else {
                          return (
                            <p key={`content-${e.id}-${i}`}>
                              {content.contentTextString}
                            </p>
                          )
                        }
                      })}
                      <ImageChanger
                        images={e.images}
                        parent={e.ensayoTitle}
                        active={selectedId === e.ensayoId}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    ensayosDataJson {
      header {
        title
        text
        bg {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      main {
        images {
          publicURL
          id
        }
        ensayoId
        ensayoTitle
        content {
          type
          contentTextTitle
          contentTextString
        }
      }
    }
  }
`

export default EnsayosLaboratorio
